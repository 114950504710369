import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {StaticImage} from 'gatsby-plugin-image';
import {graphql} from 'gatsby';
import {Link} from "gatsby-plugin-react-i18next";
import iconDependability from '../../images/about/icon-dependability.svg';
import iconInnovation from '../../images/about/icon-innovation.svg';
import iconPartnership from '../../images/about/icon-partnership.svg';
import iconService from '../../images/about/icon-service.svg';
import OfficesMobile from "../../components/contact/offices-mobile";
import OfficesDesktop from "../../components/contact/offices-desktop";


const IndexPage = () => {

  const {t} = useTranslation();
  const [windowWidth, setWindowWidth] = useState('null');
  function handleWindowResize() {
    let viewportWidth = window.innerWidth;
    setWindowWidth(viewportWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    setWindowWidth(window.innerWidth);
  }, []);

  return (
      <Layout>
        <Seo
            title={t("about_title")}
            description={t('des_about')}
        />
        <div className="about">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../images/about/hero-about.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="About Digilock"
            />
            <h1>
              <Trans>
                about_headline
              </Trans>
            </h1>
          </section>
          <section className={'reliability'}>
            <div className="container">
              <h2>
                <Trans>model_reliability</Trans>
              </h2>
              <p>
                <Trans>
                  model_reliability_p1
                </Trans>
              </p>
              <p>
                <Trans>
                  model_reliability_p2
                </Trans>
              </p>
              <p>
                <Trans>
                  model_reliability_p3
                </Trans>
              </p>
            </div>
          </section>
          <section className={'partnership'}>
            <div className="container">
              <h2>
                <Trans>power_partnership</Trans>
              </h2>
              <p>
                <Trans>
                  power_partnership_p1
                </Trans>
              </p>
              <p>
                <Trans>
                  power_partnership_p2
                </Trans>
              </p>
            </div>
          </section>
          <section className="core-values">
            <div className="container">
              <h2>
                <Trans>
                  core_values
                </Trans>
              </h2>
              <div className="figure-container">
                <div className="figure">
                  <h3>
                    <Trans>innovation</Trans>
                  </h3>
                  <img src={iconInnovation} alt="Innovation"/>
                  <p>
                    <Trans>
                      innovation_copy
                    </Trans>
                  </p>
                </div>
                <div className="figure">
                  <h3>
                    <Trans>service</Trans>
                  </h3>
                  <img src={iconService} alt="Service"/>
                  <p>
                    <Trans>
                      service_copy
                    </Trans>
                  </p>
                </div>
                <div className="figure">
                  <h3>
                    <Trans>partnership</Trans>
                  </h3>
                  <img src={iconPartnership} alt="Partnership"/>
                  <p>
                    <Trans>
                      partnership_copy
                    </Trans>
                  </p>
                </div>
                <div className="figure">
                  <h3>
                    <Trans>dependability</Trans>
                  </h3>
                  <img src={iconDependability} alt="Dependability"/>
                  <p>
                    <Trans>
                      dependability_copy
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="global-offices">
            <div className="container offices">
              <h2>
                <Trans>global_offices</Trans>
              </h2>
              {
                windowWidth < 1149 ?
                    <OfficesMobile /> :
                    <OfficesDesktop />
              }

            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
